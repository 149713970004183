import axios from 'axios'


let firstV = JSON.parse(window.localStorage.getItem('firstV')) || [] //记录初始获得的 script 文件字符串
let currentV = [] //记录当前获得的 script 文件字符串
let url = ''

switch (process.env.NODE_ENV) {
  case 'development': url = 'http://localhost:3001'; break
  case 'test': url = 'https://test.shanhaimap.com'; break
  case 'uat': url = 'https://uat.shanhaimap.com'; break
  case 'production': url = 'https://www.shanhaimap.com'; break
}

// 获得的文件字符串类似这样 `<script src="/js/chunk-vendors.1234fff.js"></script>`

async function getHtmlFun() {
  let res = await axios.get(`${url}?date=${Date.now()}`)
  if (res.status == '200') {
    let text = res.data
    if (text) {
      // 解析 html 内容，匹配 script 字符串
      let reg = /<script([^>]+)><\/script>/ig
      return text.match(reg)
    }
  }
  return []
}
function isEqual(a, b) {
  return a.length === Array.from(new Set(a.concat(b))).length
}

function resetReload(firstV) {
  window.localStorage.setItem('firstV', JSON.stringify(firstV))
  window.location.reload(true)
}

async function checkIfNewVersion(time, callback) {

  window.checkVersionInterval && clearInterval(window.checkVersionInterval)

  if (!firstV.length) {
    firstV = await getHtmlFun()
    window.localStorage.setItem('firstV', JSON.stringify(firstV))
  }

  let fun = async () => {
    currentV = await getHtmlFun()
    // 当前 script hash 和初始的不同时，说明已经更新
    if (!isEqual(firstV, currentV)) {
      callback(currentV)
    }
  }
  fun() // 先执行一次
  window.checkVersionInterval = setInterval(fun, time)
}

export function visibilitychangeFun() {
  checkIfNewVersion(1000 * 60 * 5, currentV => { resetReload(currentV) });
  window.addEventListener("visibilitychange", () => { //这个方法是监测浏览器窗口发生变化的时候执行
    if (document.visibilityState === "visible") {
      checkIfNewVersion(1000 * 60 * 5, currentV => {
        resetReload(currentV)
      });
    } else {
      window.checkVersionInterval && clearInterval(window.checkVersionInterval)
    }
  })
}
